.montserratFamily {
    font-family: "Montserrat", sans-serif;
}

.christfamily {
    font-family: "Master of Comics", serif;
}

@font-face {
    font-family: 'Master of Comics';
    src: url('../Fonts/Master_of_Comics_Personal_Use.ttf'),
}

.masterFamily {
    font-family: 'Master of Comics';
}


.continueBtn {
    border: 1px solid #DFDFDF
}

.linkColor {
    color: black !important;
}

.linkColorLight {
    color: #BABABA !important;
}


.welcomsection {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition-property: transform;
    transition-duration: 1000ms;
}

.welcomsection.hidden {
    transform: translateX(-100%);
}

.welcomenew {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition-property: transform;
    transition-duration: 700ms;
    transform: translateX(100%);
}

.welcomenew.visible {
    transform: translateX(0);
}

.welcomenew.hidden-left {
    transform: translateX(-100%);
}

.numberSelect>.PhoneInputCountry {
    border-bottom: 2px solid #EDEDED;
    padding: 10px;
}


.numberSelect>.PhoneInputCountry>.PhoneInputCountryIcon {
    width: 25px;
    height: 25px;
    overflow: hidden;
    background: none !important;
    box-shadow: none !important;
}

.numberSelect>.PhoneInputCountry>.PhoneInputCountryIcon.PhoneInputCountryIconImg {
    border-radius: 50%;
}

.numberSelect>.PhoneInputInput {
    border-bottom: 2px solid #EDEDED;
    padding: 10px;
    outline: none;
    width: 264px;
}

.codeinput {
    width: 63px;
    height: 72px;
    border-radius: 15px;
    border: 1px solid #D8DADC;
    outline: none;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.codeinput:focus {
    border: 1px solid #000000
}


.codeinput::-webkit-inner-spin-button,
.codeinput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.imgPlaceDiv {
    border: 2px solid #D8DADC;
    width: 130px;
    height: 130px;
    border-radius: 50%;
}

.accountSelect {
    margin-top: 5px;
    border-radius: 7px;
    border: none;
    background-color: #F6F6F6;
    width: 305px;
    height: 48px;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.accountSelect:focus {
    box-shadow: none;
}

.nameInput {
    width: 305px;
    height: 48px;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 500;
    padding-left: 12px;
    margin-top: 5px;
    border-radius: 7px;
    border: none;
    background-color: #F6F6F6;
    outline: none;
}

.nameInput::placeholder {
    color: black;
}

.bgnone {
    background: none;
    outline: none !important;
}

.bgnone::placeholder {
    color: #000000;

}

.navSection {
    width: 70% !important;
}

.sectionBorder {
    border-bottom: 1px solid #EDEDED;
}

.editBtn {
    border: 1px solid #000000;
    color: #000000 !important;

}

.commentSection {
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED
}

.commentInput {
    border: none;
    background: none;
    outline: none;
    padding-left: 5px;
    color: #A2A2A2;
    font-size: 13px;
    /* font-family: 'Master of Comics'; */
    width: 300px;
}

.commentInput::placeholder {
    color: #A2A2A2;
}

.sendBtn {
    border: 1px solid #1DA1F2;
    background: none;
    width: 74px;
    height: 28px;
    border-radius: 7px;
    font-family: "Montserrat", sans-serif;
    color: #000000;
}

.navMain {
    padding-bottom: 0 !important;
    z-index: 1111;
}

.replyBtn {
    border: 1px solid #EDEDED;
}

.locationSelect {
    margin-top: 10px;
    width: 100%;
    border: none;
}

.subjectInput {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid #EDEDED;
    font-size: 13px;
    color: #000000;
    font-family: "Montserrat", sans-serif;
    background: none;
    outline: none;
    padding-left: 10px;
}

.subjectInput::placeholder {
    color: #000000;
}

.emailTextArea {
    width: 100%;
    height: 300px;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
    font-family: "Montserrat", sans-serif;
    background: none;
    outline: none;
    padding: 10px;
    font-weight: 600;
    margin-top: 15px;
}

.emailTextArea::placeholder {
    color: #000000;
}


.locationSelect>.MuiFormControl-root.MuiInputBase-root>.MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-bottom: 1px solid #EDEDED !important;
}

.MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-bottom: 1px solid #EDEDED !important;
}

.MuiAutocomplete-endAdornment {
    display: none !important;
}

.rightSearch {
    display: none;
}

.rightSidebar::-webkit-scrollbar {
    display: none;
}

.leftSidebar::-webkit-scrollbar {
    display: none;
}

.lowerNav {
    display: none;
}

.followingInfo {
    width: 100%;
    padding: 8px 0 8px 15px;
    border-top: 1px solid #EDEDED;
}

.followingBtn {
    border: 1px solid #000000;
}

.followingBtnnew {
    border: 1px solid #BCBCBC;

}


.linkBlack {
    color: #666666 !important;
}


.linkBlackblue {
    color: #3BA7FF !important;
}

.settingDiv {
    box-shadow: 0px 0px 20px 0px #00000012;

}

.switch {
    position: relative;
    display: inline-block;
    width: 39px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #EAEAEA;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 3px;
    bottom: 3px;
    background-color: #000000;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #000000;
}


input:checked+.slider:before {
    background-color: #EAEAEA;
}

/* input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
} */

input:checked+.slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


.emailLower {
    border-bottom: 1px solid #EDEDED;
    padding: 20px 0;
}


.followersBtn {
    border: 1px solid #3BA7FF;
}

.postTextArea {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: 100%;
    font-size: 18px;
    color: #000000;
    padding: 10px;
    border: none;
    outline: none;
    background: none;
    line-height: 23px;
    height: 400px;
}

.postTextArea::placeholder {
    color: #000000;
}
.postWish{
    font-family: 'Master of Comics';
    width: 100%;
    font-size: 18px;
    color: #000000;
    padding: 10px;
    border: none;
    outline: none;
    background: none;
    line-height: 23px;
}
.postWish.arabic {
    text-align: right;
    font-family: 'Master of Comics';
    width: 100%;
    font-size: 18px;
    color: #000000;
    padding: 10px;
    border: none;
    outline: none;
    background: none;
    line-height: 23px;
}
.editnameInput::placeholder {
    color: #000000;
}

.editnameInput {
    color: #000000;
    outline: none;
}

.genderSelect {
    width: 100%;
    height: 51px;
    border-radius: 28px;
    color: #000000;
    line-height: 18px;
    font-size: 15px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    margin-top: 8px;
    border: none;
    background: #F6F6F6;
}


.genderSelect:focus {
    box-shadow: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}

.editBio {
    color: #000000;
    outline: none;
}

.editBio::placeholder {
    color: #000000;
}

.leftSidebars {
    width: 30%;
}


.centerSection {
    width: 40%;
}

.rightSidebars {
    width: 30%;
}


.siSection {
    padding-top: 100px;
}


@media (max-width:1524px) {
    .postTextArea {
        height: 300px;
    }

    .siSection {
        padding-top: 80px;
    }


}

@media (max-width:991px) {


    .navsearch {
        display: none;
    }


    .navLinks {
        display: none;
    }

    .navSection {
        display: none;
    }

    .navFluid {
        display: flex !important;
        justify-content: center !important;
    }


    .navFluid>.navbar-toggler {
        display: none;
    }

    .rightSearch {
        display: flex;
    }


    .leftSidebar,
    .rightSidebar {
        z-index: 11111;
    }


    .sideOff {
        z-index: 111111;
    }


    .boxShadowDivs {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }


    .lowerNav {
        display: flex;
        z-index: 11111111;
    }


    .leftSidebars {
        width: unset;
        height: auto;
    }


    .centerSection {
        width: 100%;
    }

    .rightSidebars {
        width: unset;
        height: auto;
    }

    .siSection {
        padding-top: 0;
    }

}

@media (max-width:767px) {

    .numberSelect>.PhoneInputInput {
        border-bottom: 2px solid #EDEDED;
        padding: 5px;
        outline: none;
        width: 250px;
    }

    .numberSelect>.PhoneInputCountry {
        padding: 5px;
    }

    .codeinput {
        width: 40px;
        height: 40px;
        border-radius: 10px;
    }


    .accountSelect,
    .nameInput {
        height: 40px;
        width: 270px;
        font-size: 12px;
    }

    .emailTextArea {
        height: 200px;
    }

    .commentInput {
        width: 200px;
        font-size: 11px;
    }

    .sendBtn {
        width: 50px;
        height: 25px;
        font-size: 10px;
    }

    .postTextArea {
        height: 200px;
        font-size: 14px;
    }

    .followingInfo {
        padding: 8px 0 8px 8px;
    }


    .emailLower {
        padding: 10px 0;
    }


    .genderSelect {
        font-size: 13px;
    }


}